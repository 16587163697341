import { SpecterProducts } from "@prisma/client"
import { TalentCompanyRelation } from "~/routes/__protected/api/company/$id/related-talent.$relation"
import { AsyncInputProperty } from "~/routes/__protected/api/input-options.$product.$property"
import { getProductSignalSearchParams } from "./hooks/useProductFilters"
import { ViewMode } from "./hooks/useViewMode"

// Workaround for URLSearchParams not having a size property
// Open issue to fix this:
// https://github.com/microsoft/TypeScript/issues/54466
declare global {
  interface URLSearchParams {
    size: number
  }
}

export const cacheKeys = {
  ai: (prompt?: string) => ["ai", prompt],
  affinityCheckSignal: (product: string, id: string) => [
    "affinityCheckSignal",
    product,
    id,
  ],
  affinityToken: ["affinityToken"],
  tokenVerify: ["tokenVerify"],

  userIsTeamAdmin: ["userIsTeamAdmin"],
  sidebarState: ["sidebarState"],

  quickSearchResults: (term: string, tab?: SpecterProducts) => [
    "quickSearchResults",
    term,
    ...(tab ? [tab] : []),
  ],
  quickSearchCounts: (term: string) => ["quickSearchCounts", term],

  signalsById: (ids: string[]) => ["signalsById", ids],
  signal: (id: string) => ["signal", id],
  talentSignal: (id: string) => ["talentSignals", id],
  strategicSignal: (id: string) => ["strategicSignals", id],
  companyStats: () => ["companyStats"],
  companySignal: (id: string) => ["companySignals", id],
  companyManualEnrichment: () => ["companyManualEnrichment"],
  companySignalsSortParam: ["companySignalsSortParam"],
  signals: (
    product: SpecterProducts,
    searchParams: URLSearchParams,
    viewMode: ViewMode = ViewMode.Table
  ): any[] => {
    const searchId = searchParams.get("searchId")
    const search = searchParams.get("search")
    const sort = searchParams.get("sort")
    const query = searchParams.get("query")
    const listId = searchParams.get("listId")
    const queryId = searchParams.get("queryId")

    if (queryId) {
      return ["signals", product, viewMode, query, sort, search, { queryId }]
    }

    if (searchId) {
      return ["signals", product, viewMode, query, sort, search, { searchId }]
    }

    if (listId) {
      return ["signals", product, viewMode, query, sort, search, { listId }]
    }

    return ["signals", product, viewMode, query, sort, search]
  },
  signalsCount: ({
    product,
    searchParams,
  }: {
    product: SpecterProducts
    searchParams: URLSearchParams
  }): any[] => {
    const listId = searchParams.get("listId")

    if (searchParams.has("search")) {
      return [
        "signals",
        `${product}-count`,
        searchParams.get("search")?.toString(),
      ]
    }

    return [
      "signals",
      `${product}-count`,
      getProductSignalSearchParams(product, searchParams),
      ...(listId ? [listId] : []),
    ]
  },
  userSessions: ["userSessions"],
  emailVerification: ["emailVerification"],

  actionOptions: (product: SpecterProducts, signalIds?: string[]) => [
    "actionOptions",
    product,
    ...(signalIds ? signalIds : []),
  ],

  userSavedSearches: (product: SpecterProducts, searchParams: string = "") => [
    "userSavedSearches",
    product,
    searchParams,
  ],
  userSavedSearch: (id: string): [string, string] => ["userSavedSearch", id],
  userSearchDetail: (id: number) => ["userSearchDetail", id],
  signalFavourite: (id: string) => ["signalFavourite", id],
  signalAddedToList: (id: string) => ["signalAddedToList", id],

  userPermissions: ["userPermissions"],

  userLists: ({
    product,
    signalId,
    isDashboard,
    searchParams,
  }: {
    product: SpecterProducts
    searchParams?: URLSearchParams
    signalId?: string
    isDashboard?: boolean
  }) =>
    [
      "userLists",
      product,
      searchParams?.toString(),
      signalId,
      !!isDashboard,
    ].filter(Boolean),
  userList: (listId: string, searchParams?: URLSearchParams) => [
    "userList",
    listId,
    ...(searchParams && searchParams.size > 0 ? [searchParams.toString()] : []),
  ],
  userListDetails: (listId: string) => ["userListDetails", listId],

  globalHubLists: (product: SpecterProducts) => ["globalHubLists", product],
  globalHubSearches: (product: SpecterProducts) => [
    "globalHubSearches",
    product,
  ],

  talentThisWeek: ["talentThisWeek"],
  strategicThisWeek: ["strategicThisWeek"],

  integrations: ["integrations"],
  enrichmentSources: ["enrichmentSources"],
  integrationCompaniesCount: ["integrationCompaniesCount"],
  integrationSyncSources: (integrationKey?: string) => [
    "integrationSyncSources",
    integrationKey,
  ],
  integrationSyncs: (integrationKey?: string) => [
    "integrationSyncs",
    integrationKey,
  ],
  integrationDataSources: (
    product: SpecterProducts,
    integrationKey?: string
  ) => ["integrationDataSources", product, integrationKey],
  integrationLists: (integrationKey?: string) => [
    "integrationsLists",
    integrationKey,
  ],
  integrationMeta: (key?: string) => ["integrationMeta", key],
  integrationEnrichments: (integrationKey?: string) => [
    "integrationEnrichments",
    integrationKey,
  ],

  zapier: ["zapier"],

  teamMembers: (query: string = "") => ["teamMembers", query],
  shareMembers: ["shareMembers"],

  adminUsers: (query: string = "") => ["adminUsers", query],
  adminCompanies: (query: string = "") => ["adminCompanies", query],
  adminCompanyDetail: (id: string = "") => ["adminCompanyDetail", id],
  adminClients: (query: string = "") => ["adminClients", query],

  topSignals: (
    product: SpecterProducts,
    leftToggle: number,
    rightToggle: number
  ) => ["topSignals", product, leftToggle, rightToggle],

  searchesSummary: (product: SpecterProducts) => ["searchesSummary", product],
  newSignalsOverTime: (product: SpecterProducts) => [
    "newSignalsOverTime",
    product,
  ],
  suggestedSearchesMenu: (section: string) => [
    "suggestedSearchesMenu",
    section,
  ],
  companyDeliveryMonth: ["companyDeliveryMonth"],
  investor: (id: string) => ["investor", id],
  inputOptions: (property: AsyncInputProperty) => ["inputOptions", property],

  tableViews: (product: SpecterProducts) => ["tableViews", product],

  investorStrategicSignals: (id: string) => ["investorStrategicSignals", id],

  relatedTalentSignals: (id: string, relation: TalentCompanyRelation) => [
    "relatedTalentSignals",
    id,
    relation,
  ],
  relatedTalentAll: (id: string) => ["relatedTalentAll", id],
  relatedStrategicSignals: (id: string) => ["relatedStrategicSignals", id],
  teamBreakdown: (id: string) => ["teamBreakdown", id],
  landscapes: (id?: string) => (id ? ["landscapes", id] : ["landscapes"]),
  landscapesGlobal: () => ["landscapes", "globalPromo"],
  landscapesEditable: (product: SpecterProducts) => [
    "landscapes",
    "editable",
    product,
  ],
  peopleSignal: (id: string) => ["peopleSignal", id],
  peopleTalentSignals: (id: string) => ["peopleTalentSignals", id],
  peopleStratintelSignals: (id: string) => ["peopleStratintelSignals", id],
} as const
