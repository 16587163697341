import { SortDirection } from "./db/queries/types"

export const updateSearchParams = <
  TQuery extends Record<string, any>,
  TSort extends Record<string, SortDirection>
>(
  searchParams: URLSearchParams,
  options: {
    query?: TQuery
    sort?: TSort
  }
) => {
  const query = options.query ?? JSON.parse(searchParams.get("query") ?? "{}")
  const sort = options.sort ?? JSON.parse(searchParams.get("sort") ?? "{}")
  const search = searchParams.get("search")
  const listId = searchParams.get("listId")

  const hasQuery = query && Object.keys(query).length > 0
  const hasSort = sort && Object.keys(sort).length > 0

  // If query has semanticSearch and no sort is specified, make semanticSearch the sort
  let finalSort = sort
  if (query?.semanticSearch && !hasSort) {
    finalSort = {
      semanticScore: "desc" as SortDirection,
    }
  }

  const params = {
    ...(hasQuery ? { query: JSON.stringify(query) } : search && { search }),
    ...(Object.keys(finalSort).length > 0 && {
      sort: JSON.stringify(finalSort),
    }),

    // If new search params have no query, then ignore all the edit query params
    ...(hasQuery && updatedEditQueryParams(searchParams)),

    // Keep listId if it exists
    ...(listId && { listId }),
  }

  return new URLSearchParams(params)
}

const updatedEditQueryParams = (searchParams: URLSearchParams) => {
  const params: Record<string, string> = {}

  const searchId = searchParams.get("searchId")

  if (searchId) {
    params["searchId"] = String(searchId)
  }

  return params
}
